<template>
    <div class="px-3 py-2"> 
        <v-form
            ref="forinv"
            lazy-validation
        >
        <b-sidebar id="add_category" aria-labelledby="sidebar-no-header-title" width="700px" no-header backdrop :style="`direction:`+$parent.lang.ldir" right :title="$parent.lang.add_category" shadow >
            <template #default="{ hide }">
                <div class="m-1 text-start" style="direction:rtl">
                    <div style="width:120px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                    <span>{{$parent.lang.add_category}}</span>
                    </div>
                    <div @click="hide" id="iHideMe" style="width:100px;text-align:center;float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
                    <span>{{$parent.lang.close}}</span>
                </div>
                </div>
                <v-divider></v-divider>
                <div class="px-3 py-2" style="direction:rtl">
                    <v-row>
                        <v-col cols="6">
                            <label>{{$parent.lang.category_name_ar}}</label>
                            <b-form-input class="inborder"
                                :label="$parent.lang.category_name_ar"
                                v-model="namear"
                                >{{ namear }}</b-form-input>
                        </v-col>
                        <v-col cols="6">
                            <label>{{$parent.lang.category_name_en}}</label>
                            <b-form-input class="inborder"
                                :label="$parent.lang.category_name_en"
                                v-model="nameen"
                                >{{ nameen }}</b-form-input>
                        </v-col>
                        <v-col cols="6">
                            <label>{{$parent.lang.product_categories}}</label>
                            <b-input-group>
                            <b-form-select class="selborder"
                                v-model="parentid"
                                :options="product_categories"
                                :label="$parent.lang.product_categories"
                                ></b-form-select>
                                <b-input-group-addon style="margin-right:-20px;margin-top:5px;">
                                    <i class="fas fa-arrow-down"></i>
                                </b-input-group-addon>
                            </b-input-group>
                        </v-col>
                        <v-col cols="6">
                            <label>{{$parent.lang.type}}</label>
                                <b-input-group>
                                <b-form-select class="selborder"
                                    v-model="type"
                                    :options="types"
                                    :label="$parent.lang.type"
                                ></b-form-select>
                                <b-input-group-addon style="margin-right:-20px;margin-top:5px;">
                                    <i class="fas fa-arrow-down"></i>
                                </b-input-group-addon>
                            </b-input-group>
                        </v-col>
                    </v-row>
                </div>
            </template>
            <template #footer="" class="shadow">
                <div class="d-flex text-light align-items-center px-3 py-2">
                    <b-button type="button" @click="addcat()" hide id="addBTN" variant="success" class="ma-2 btn-sm" style="width:130px;">{{$parent.lang.add}} / {{$parent.lang.update}}</b-button>
                </div>
            </template>
        </b-sidebar>
        </v-form>
        <vue-snotify></vue-snotify>
    </div>
</template>

<script>
import axios from 'axios'
import {SnotifyPosition} from 'vue-snotify';
export default {
    data() {
        return {
            namear: '',
            nameen: '',
            parentid: 0,
            type: 1,
            id: 0,
            product_categories: [],
            types: [
                {
                    text: this.$parent.lang.main_cat,
                    value: 0
                },
                {
                    text: this.$parent.lang.sub_cat,
                    value: 1
                }
            ],
        }
    },
    created() {
        this.getPCats();
    },
    methods: {
        changeType(type){
            this.type = type;
        },
        addcat() {
            if(this.nameen == '' && this.namear == ''){
                let message = "يرجى اضافة الاسم العربي للقسم";
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                this.invoice.payment.cash = this.full_ftotal
                return false;
            }
            else if(this.nameen != '' && this.namear == ''){
                this.namear = this.nameen
            }
            else if(this.namear != '' && this.nameen == ''){
                this.nameen = this.namear
            }
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type','addUpdateCat');
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[lang]',this.$parent.lang.langname);
            post.append('data[id]',this.id);
            post.append('data[namear]',this.namear);
            post.append('data[nameen]',this.nameen);
            post.append('data[parentid]',this.parentid);
            post.append('data[type]',this.type);
            axios.post(this.$SAMCOTEC.r_path,post)
            .then((response) => {
              const res = response.data;
              this.$parent.getCats();
              this.getPCats();
              this.ihide()
            });
        },
        ihide(){
            document.getElementById('iHideMe').click();
        },
        getPCats(){
          const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
          post.append('type','getPCats');
          post.append('auth',this.$cookies.get(this.$COOKIEPhase));
          post.append('data[id]','0');
          post.append('data[lang]',this.$parent.lang.langname);
        
          axios.post(this.$SAMCOTEC.r_path,post)
            .then((response) => {
              const res = response.data;
              
              let t = [{
                  text: this.$parent.lang.main_cat,
                  value: 0
              }];
              if(typeof res.results !== 'undefined' && typeof res.results.data !== 'undefined'){
                  for(let i=0;i<res.results.data.length;i++){
                      t.push({
                          text: this.$parent.lang.langname == "ar" ? res.results.data[i].namear : res.results.data[i].nameen,
                          value: res.results.data[i].id
                      })
                  }
              }
              this.product_categories = t;
          });
        }
    },
    
}
</script>